var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"root-highlights-partial"},[_c('div',{staticClass:"highlights-partial"},[_c('section-title',{attrs:{"title":"Portfolio","subtitle":"Cutting Edge Software Development"}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"item"},[_c('section-highlight',{attrs:{"title":"Blockchain","promo":"Building excellent Distributed Apps based on Ethereum and Bitcoin blockchains: Wallets, Exchanges, Registries.","counters":[
              { name: 'engineers', label: 'Qualified Engineers', value: 10 },
              { name: 'projects', label: 'Successful Projects', value: 3 },
            ],"tags":[
              { name: 'ethereum', label: 'Ethereum' },
              { name: 'web3', label: 'Web3' },
              { name: 'solidity', label: 'Solidity' },
              { name: 'bitcoin', label: 'Bitcoin' },
              { name: 'plasma', label: 'Plasma' },
              { name: 'dapps', label: 'DApps' }
            ]}})],1),_c('div',{staticClass:"item"},[_c('section-highlight',{attrs:{"title":"Machine Learning","promo":"Mastering innovations in Data Analysis, Machine Learning, Predictive Analytics, and Cloud Computing.","counters":[
              { name: 'engineers', label: 'Qualified Engineers', value: 6 },
              { name: 'projects', label: 'Successful Projects', value: 2 },
            ],"tags":[
              { name: 'ml', label: 'Machine Learning' },
              { name: 'nn', label: 'Neural networks' },
              { name: 'cloud', label: 'Cloud Computing' }
            ]}})],1),_c('div',{staticClass:"item"},[_c('section-highlight',{attrs:{"title":"Banking","promo":"Developing reliable, secure and trustworthy software for finance and banking: Accounting, Payment Processing, Enterprise Software.","counters":[
              { name: 'engineers', label: 'Qualified Engineers', value: 12 },
              { name: 'projects', label: 'Successful Projects', value: 2 },
            ],"tags":[
              { name: 'security', label: 'Security' },
              { name: 'fault-tolerance', label: 'Fault-tolerance' },
              { name: 'setup', label: 'Trusted Setup' },
              { name: 'support', label: 'Customer Support' }
            ]}})],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }