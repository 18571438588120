/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo': {
    width: 16,
    height: 16,
    viewBox: '0 0 64 23',
    data: '<path pid="0" d="M30.77 22.4h32.45a.1.1 0 1 1 0 .2H30.77a.4.4 0 0 1-.388.3H1.292a.4.4 0 1 1 0-.8h29.09a.4.4 0 0 1 .388.3zm31.446-3.1a.2.2 0 1 1 0 .4H28.85a.5.5 0 0 1-.458.3H2.288a.5.5 0 0 1 0-1H28.39a.5.5 0 0 1 .458.3h33.367zm-34.321-3.1h33.327a.3.3 0 1 1 0 .6H27.895a.6.6 0 0 1-.52.3H4.295a.6.6 0 1 1 0-1.2h23.08a.6.6 0 0 1 .52.3zm31.3-3.1c.224 0 .407.18.407.4 0 .22-.183.4-.408.4H24.25a.717.717 0 0 1-.585.3H7.315a.707.707 0 0 1-.713-.7c0-.387.32-.7.713-.7h16.35c.242 0 .456.119.585.3h34.944zM35 2.3a.8.8 0 1 1 0-1.6h8.438a.8.8 0 1 1 0 1.6H35zm-4.623 2.9a.7.7 0 1 1 0-1.4h18.824a.7.7 0 1 1 0 1.4H30.377zm-4.005 2.9a.6.6 0 1 1 0-1.2h27.84a.6.6 0 1 1 0 1.2h-27.84zM24.365 11a.5.5 0 0 1 0-1h32.862a.5.5 0 1 1 0 1H24.365zM12 11.3a.8.8 0 1 1 0-1.6h6.563a.8.8 0 1 1 0 1.6H12z"/>'
  }
})
