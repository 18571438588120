var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"root-offers-partial"},[_c('div',{staticClass:"offers-partial"},[_c('section-title',{attrs:{"title":"Pricing","subtitle":"Payment structure customized for your business needs"}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"item"},[_c('section-offer',{attrs:{"title":"Hire an Engineer","promo":"Book one or multiple experts in Java, Spring, JavaScript, React, Vue, HTML5, SASS/SCSS, LESS, Python, ML, QA","metrics":[
              { id: 1, text: 'From $22/hour' }
            ],"details":"Give us a task and we will provide a timeline, estimated rates and guaranteed quality."}})],1),_c('div',{staticClass:"item"},[_c('section-offer',{attrs:{"title":"Hire a Team","promo":"Bring in a professional team of developers, QA, project manager and tech lead.","metrics":[
              { id: 1, text: 'From $25000/month' },
              { id: 2, text: 'From 5 professionals' }
            ],"details":"Our experts will help you plan, architect and develop any project."}})],1),_c('div',{staticClass:"item"},[_c('section-offer',{attrs:{"is-highlighted":"","title":"Make Magic","promo":"Pitch your idea, sit back and let our experts do all the work; from UI/UX to development and testing to production installation.","metrics":[
              { id: 1, text: 'From $100000/project' },
              { id: 2, text: 'From 6 months' }
            ],"details":"We offer an end-to-end custom solution. From design to production deployment and maintenance."}})],1),_c('div',{staticClass:"item"},[_c('section-offer',{attrs:{"title":"Support Legacy","promo":"Entrust your existing project. We can maintain your legacy code and provide inexpensive technical support to your users.","metrics":[
              { id: 1, text: 'From $25/hour' }
            ],"details":"No mater how big or how old your project is, we can take care of it."}})],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }